// ** Reducers Imports

import authentication from "./authentication"
import user from "./user"
import regions from "./region"
import districts from "./districts"
import countries from './country'

import users from "./users";
import roles from "./roles";
import patients from './patients'

import occupations from "./general/occupation"
import languages from './general/language'
import religions from './general/religion'
import classifications from './medical/classification'
import hospitals from "./medical/hospital"
import clinicians from "./medical/clinicians"
import clinician_types from "./medical/clinician_types"
import sponsors from "./general/sponsors"
import patient_case from './case/case'
import referral_patients from "./referral_patients"
import general_patients from './patients_general'

import all_cases from "./all_cases"
import payment_details from "./xero/payment_details"
import user_roles from "./user_roles"

const rootReducer = { 
    authentication, user, users, roles,
    patients, referral_patients,
    all_cases,
    regions, districts, countries,
    languages, religions, occupations,
    classifications, hospitals, clinicians, clinician_types,
    sponsors,
    patient_case,
    general_patients,
    payment_details,
    user_roles
}

export default rootReducer