import { Button, Box, Stack, Chip, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GeneralDialog from "../../../../components/Dialogs/CustomDialog";
import instance from "../../../../auth/utils/useJwt";

export default function ExportUsers() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloadLink, setDownloadLink] = useState(null);

    const onExportClick = () => {
        setLoading(true);
        // Assign groups
        setLoading(true);
        instance.get("/admin/users?type=export").then(res => {
            //replace http with https
            res.data = res.data.replace('http://', 'https://');
            window.open(res.data, '_blank');
            setDownloadLink(res.data);
            setLoading(false);
            //setOpen(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    };

    return (
        <>
        <Box>
            <Button size="small" variant="outlined" onClick={() => setOpen(true)}>Export Users</Button>
            </Box>
            <GeneralDialog
                open={open} handleClose={() => setOpen(false)}
                title="Export Users"
                actions={
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" size="small" onClick={() => setOpen(false)}
                            disabled={loading}
                        >Cancel</Button>
                        <Button variant="contained" size="small" onClick={onExportClick} disabled={loading}>
                            {loading ? "Processing..." : "Export"}
                        </Button>
                    </Stack>
                }
            >
                <Box>
                    <Typography>
                        Export users

                        {
                            downloadLink && <a href={downloadLink} download>Download file</a>

                        }
                    </Typography>
                </Box>
            </GeneralDialog>
        </>
    );
}
