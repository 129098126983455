import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props) {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={(theme) => ({
                    color: "#ccc"//theme.palette.grey[200],
                    //   ...theme.applyStyles('dark', {
                    //     color: theme.palette.grey[800],
                    //   }),
                })}
                {...props}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                {...props}
                // disableShrink
                sx={(theme) => ({
                    color: '#1a90ff',
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                    //   ...theme.applyStyles('dark', {
                    //     color: '#308fe8',
                    //   }),
                })}

                thickness={4}
                {...props}
            />
        </Box>
    );
}


export default function CircularProgressFeedback(props) {
    return (
        <FacebookCircularProgress {...props} />
    );
}
