import { Box, Card, CardContent, CardHeader, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { EXPENSES_ROLES, DONATION_ROLES } from "../../../../../configs/roles_list";

export default function UserDetails({ data }) {
    const { phone, country, region, timezone, xero_access, xero_pam, donations, privileges, role } = data;

    const privileges_names = privileges?.map(item => item.name) || [];

    // Check if any of EXPENSES_ROLES is in privileges_names
    const hasExpenseRole = EXPENSES_ROLES.some(expenseRole => privileges_names.includes(expenseRole));
    const hasDonationRole = DONATION_ROLES.some(donationRole => privileges_names.includes(donationRole));
    return (
        <Box>
            <Card>
                <CardHeader title="User Details" sx={{ fontSize: '17px' }} />
                <CardContent>
                    <Box>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>{phone ? phone : 'N/A'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>{country ? country : 'N/A'}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>Region</TableCell>
                                    <TableCell>{region ? region : 'N/A'}</TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>Timezone</TableCell>
                                    <TableCell>{timezone ? timezone : 'N/A'}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>Xero Access</TableCell>
                                    <TableCell>{hasExpenseRole ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Donations</TableCell>
                                    <TableCell>{hasDonationRole ? 'Yes' : 'No'}</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
