import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { Grid, Stack } from '@mui/material';
import instance from '../../../auth/utils/useJwt';
import { fetchUsers } from '../../../redux/users';
import { useDispatch } from 'react-redux';
import CustomInputLabel from '../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../components/Dialogs/CustomDialog';


export default function AddUser() {
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        first_name: '', last_name: '', email: '', primary_email: '', phone: ''
    })
    const [processing, setProcessing] = React.useState(false)
    const dispatch = useDispatch()

    const onChangeField = (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            })
    }

    const onAddUser = () => {
        setProcessing(true)
        instance.post("/admin/google-admin/user", formData).then(res => {
            setOpen(false)
            dispatch(fetchUsers())
            setProcessing(false)
        }).catch(err => {
            console.log(err)
            setProcessing(false)
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size="small" onClick={handleClickOpen}>
                Add User
            </Button>
            <GeneralDialog open={open} handleClose={handleClose} title={'Add New User'}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                        <Button onClick={onAddUser} variant="contained" color="primary" autoFocus>
                            { processing ? 'Processing...' : 'Add User' }
                        </Button>
                    </Stack>
                }>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <CustomInputLabel>First Name</CustomInputLabel>
                            <TextField size="small" fullWidth name='first_name' value={formData.first_name} onChange={onChangeField} />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomInputLabel>Last Name</CustomInputLabel>
                            <TextField size="small" fullWidth name='last_name' value={formData.last_name} onChange={onChangeField} />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomInputLabel>Primay Email</CustomInputLabel>
                            <TextField size="small" fullWidth name='email' value={formData.email} onChange={onChangeField} />
                            <CustomInputLabel sx={{ fontSize: 11 }}>This email will be used as the login email</CustomInputLabel>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomInputLabel>&nbsp;</CustomInputLabel>
                            <TextField size="small" sx={{
                                border: 'none', '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: "none"
                                    },
                                }
                            }} fullWidth value={"@romac.org.au"} disabled />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomInputLabel>Secondary Email</CustomInputLabel>
                            <TextField size="small" fullWidth name='secondary_email' value={formData.secondary_email} onChange={onChangeField} />
                            <CustomInputLabel sx={{ fontSize: 11 }}>This email will be used for all notifications</CustomInputLabel>

                        </Grid>
                        <Grid item xs={6} md={6}>
                            <CustomInputLabel>Phone</CustomInputLabel>
                            <TextField size="small" fullWidth name='phone' value={formData.phone} onChange={onChangeField} />

                        </Grid>
                    </Grid>
                </Box>
            </GeneralDialog>
        </div>
    );
}
