import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Button } from '@mui/material';

import useSnackbar from '../../../../components/Dialogs/SnackBar';
import instance from '../../../../auth/utils/useJwt';
import LinearProgress from '@mui/material/LinearProgress';
import AddEditGuardian, { relationship_map } from '../components/AddEditGuardian';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import DeleteGuardianButton from '../components/DeleteGuardian';
import PatientAction from '../../../../components/PermissionsWrappers/PatientAction';

export default function Guardians(props) {
  const [SnackbarComponent, showSnackbar] = useSnackbar();
  const [apiReq, setApiReq] = useState({ loading: false, error: null })
  const [loading, setLoading] = useState(true)
  const [openAddGuardian, setOpenAddGuardian] = React.useState(false)
  const [selectedGuardian, setSelectedGuardian] = useState(null)
  const [guardians, setGuardians] = React.useState([])

  const get_guardians = () => {
    setLoading(true)
    instance.get(`/guardian?id=${props.id}`).then(res => {
      setGuardians(res.data)
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  const onGuadianButtonClick = () => {
    if (openAddGuardian) {
      setSelectedGuardian(null)
    }
    setOpenAddGuardian(!openAddGuardian)
  }


  React.useEffect(() => {
    // On First load fetch all guardians
    get_guardians()
  }, [])

  const onSelect = (row_data) => {
    // On View guradian click, set data and open form
    const temp_row = { ...row_data }
    setSelectedGuardian(temp_row)
    setOpenAddGuardian(true)
  }

  const onSubmit = (data) => {
    setApiReq({ loading: true, error: null })

    data.patient_id = props.id
    if (selectedGuardian) {
      data.id = selectedGuardian.nextofkin_id

      instance.patch("/guardian", data).then(res => {
        setApiReq({ loading: false, error: null })
        showSnackbar("Record updated successfully", "success")

        get_guardians()
        onGuadianButtonClick() // This will close form and clear form
      }).catch(err => {
        console.log(err)

        setApiReq({ loading: false, error: null })
      })
    } else {
      instance.post("/guardian", data).then(res => {
        setApiReq({ loading: false, error: null })
        showSnackbar("Record added successfully", "success")

        get_guardians()
        onGuadianButtonClick() // This will close form and clear form
      }).catch(err => {
        console.log(err)

        setApiReq({ loading: false, error: null })
      })
    }
  }


  return (
    <Box>
      <SnackbarComponent />
      {
        loading &&
        <Box>
          <LinearProgress />
        </Box>
      }
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
          <TableHead sx={{ backgroundColor: '#f6f6f7' }}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="right" sx={{ py: 1 }}>First Name</TableCell>
              <TableCell align="right">Last Name</TableCell>
              <TableCell align="right">Relationship</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guardians.map((row) => (
              <TableRow
                key={row.guardian_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  {row.guardian_id}
                </TableCell>
                <TableCell align="right">{row.first_name}</TableCell>
                <TableCell align="right">{row.last_name}</TableCell>
                <TableCell align="right">{relationship_map[row.relationship]}</TableCell>
                <TableCell align="right">
                  <Button startIcon={<VisibilityIcon />} size='small' variant='outlined' onClick={() => onSelect(row)}>View</Button>
                  <PatientAction>
                    <DeleteGuardianButton row={row} get_guardians={get_guardians} id={props.id} />
                  </PatientAction>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PatientAction>
        <Box sx={{ py: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button startIcon={
            openAddGuardian ? <CloseIcon /> : <AddIcon />
          } variant={openAddGuardian ? 'outlined' : 'contained'} onClick={onGuadianButtonClick}>
            {selectedGuardian && openAddGuardian ? "Close" : (
              openAddGuardian ? "Close" : "Add Guardian"
            )}
          </Button>
        </Box>
      </PatientAction>
      {/* {openAddGuardian && */}
      <AddEditGuardian open={openAddGuardian} data={selectedGuardian} handleClose={onGuadianButtonClick} apiReq={apiReq} onSubmit={onSubmit} />

    </Box>
  );
}
