import React, { useEffect, useState } from "react";
import instance from "../../../../../auth/utils/useJwt";
import { Box, Button, Grid, Menu, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
export default function PermissionsSelector({
    selectedPermissions, setSelectedPermissions
}) {
    const [loading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState([]);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const fetchPermissions = () => {
        setLoading(true);
        instance.get("/admin/user/privileges").then(res => {
            setPermissions(res.data.filter(p => !["Basic", "Patients", "Cases"].includes(p.category)));
            setLoading(false);
        }).catch(err => {
            setError("Failed to fetch permissions");
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePermissionSelect = (permission) => {
        const selPermission = {
            "privilege_id": permission.id,
            "name": permission.name,
            "category": permission.category,
            "description": permission.description
        }
        setSelectedPermissions([...selectedPermissions, selPermission]);
        handleMenuClose();
    }

    
 

    return (
        <div>
            <Button aria-controls="permissions-menu" aria-haspopup="true"
            disabled={loading}
            startIcon={<AddIcon />}
            onClick={handleMenuOpen}>
                Add Permission
            </Button>
            <Menu
                id="permissions-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <Box>
                    <Grid container spacing={2} sx={{ maxWidth: "400px", minWidth: "350px" }}>
                        <Grid item md={5} sx={{
                            borderRight: "1px solid #e0e0e0",
                            py: 1, px: 2
                        }}>
                            {
                                permissions.map((permission, index) => (
                                    <Box key={index}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        // onMouseLeave={() => setHoveredIndex(null)}
                                        sx={{
                                            cursor: "pointer",
                                            px: 2, py: 0.5,
                                            "&:hover": {
                                                backgroundColor: hoveredIndex === index ? "#f0f0f0" : ""
                                            }
                                        }}>
                                        {permission.category}
                                    </Box>
                                ))
                            }
                        </Grid>
                        <Grid item md={7}>
                            <Typography variant="caption">Permissions</Typography>

                            {
                                permissions[hoveredIndex]?.privileges.map((privilege, index) => (
                                    <Box key={index} sx={{
                                        px: 1, py: 0.5, 
                                        fontSize: "0.9rem",
                                        "&:hover": { backgroundColor: "#f0f0f0" },
                                        cursor: "pointer"
                                    }}
                                        onClick={() => handlePermissionSelect(privilege)}>
                                        {privilege.name}
                                    </Box>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Menu>
        </div>
    );
}
