import React, { useState, useEffect } from "react";
import AppLayout from "../../../components/layout/AppLayout";
import { Box, Typography, Grid, Card, CardHeader, CardContent, LinearProgress, Stack, Button, IconButton } from "@mui/material";

import instance from "../../../auth/utils/useJwt";
import { Link, useParams } from "react-router-dom";
import Sidebar from "./Sidebar";
import SingleTraining from "./SingleTraining";

export default function TrainingModulePages() {
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const [tableOfCotent, setTableOfContent] = useState([
        { title: "Introduction", id: "introduction", completed: true },
        { title: "Chapter 1", id: "chapter-1", completed: false },
        { title: "Chapter 2", id: "chapter-2", completed: false },
    ])
    const [activeTrainingId, setActiveTrainingId] = useState(null)

    const [trainings, setTrainings] = useState([])
    const [loading, setLoading] = useState(true)
    const [folderMeta, setFolderMeta] = useState({})
    const [progress, setProgress] = useState({
        completed: 0,
        total: 0
    })
    const { slug } = useParams()

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get(`/training/pages?slug=${slug}`).then(res => {
            setLoading(false)
            setTrainings(res.data?.trainings)
            setFolderMeta(res.data?.folder)

            // Make toc
            const headings = res.data?.trainings.map(item => ({
                title: item.title,
                id: item.slug
            }))
            setTableOfContent(headings)
            setActiveTrainingId(headings[0].id)


        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])

    const onTrainingIdClick = (id) => {
        setActiveTrainingId(id)
        // Also mark as completed
        const newTableOfContent = tableOfCotent.map(item => {
            if (item.id === id) {
                item.completed = true
            }
            return item
        })
        setTableOfContent(newTableOfContent)
    }

    const nextPage = () => {
        const currentIndex = trainings.findIndex(item => item.slug === activeTrainingId)
        if (currentIndex < trainings.length - 1) {
            setActiveTrainingId(trainings[currentIndex + 1].slug)
        }
    }

    const prevPage = () => {
        const currentIndex = trainings.findIndex(item => item.slug === activeTrainingId);
        if (currentIndex > 0) {
            setActiveTrainingId(trainings[currentIndex - 1].slug)
        }
    }

    const trainingsDone = trainings?.filter(item => item.completed)?.length
    return (
            <Box sx={{
                backgroundColor: "#f9f9f9", display: "flex", flexDirection: "row", height: "100vh"
            }}>
                <Sidebar open={sidebarOpen} setOpen={setSidebarOpen}
                loading={loading}
                title={folderMeta.name} totalTrainings={trainings?.length} completedTrainings={trainingsDone}
                trainings={tableOfCotent}
                activeTrainingId={activeTrainingId} setActiveTrainingId={onTrainingIdClick} />
                
                <SingleTraining
                mainLoading={loading}
                open={sidebarOpen} setOpen={setSidebarOpen} activeTrainingId={activeTrainingId} folderMeta={folderMeta} 
                nextPage={nextPage} prevPage={prevPage} trainings={trainings}
                />
            </Box>
    )
}