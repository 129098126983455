import { Avatar, Button, Checkbox } from "@mui/material";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import PersonIcon from '@mui/icons-material/Person';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

import Chip from '@mui/material/Chip';
import { useForm, Controller } from 'react-hook-form';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useDispatch, useSelector } from "react-redux";
import { fetchRegions } from "../../../redux/region";
import { fetchLanguages } from "../../../redux/general/language";
import { fetchReligions } from "../../../redux/general/religion";
import { fetchMedicalClassifications } from "../../../redux/medical/classification";
import { fetchCountries } from "../../../redux/country";
import useSnackbar from "../../../components/Dialogs/SnackBar";
import EditAvatar from "./EditAvatar";
import { DATE_FORMAT } from "../../../configs/basic";
import { formatDate } from "../../../components/forms/utils";
import CustomInputLabel from "../../../components/forms/CustomInputLabel";
import { hasWritePermission } from "../../../redux/user";
import PatientAction from "../../../components/PermissionsWrappers/PatientAction";

export default function EditForm(props) {
    const { apiReq } = props
    const canWrite = useSelector(hasWritePermission)
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    
    const [patientData, setPatientData] = useState(null)
    const dispatch = useDispatch()
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (props.data) {
            var patient_data = props.data
            patient_data.dob = patient_data.dob.replaceAll(".", "-")
            patient_data.understands_english = patient_data.understands_english === "Yes" ? true : false
            patient_data.has_birth_certificate = patient_data.has_birth_certificate === "Has" ? true : false
            patient_data.languages = patient_data.languages.map(({ id, name }) => ({ label: name, value: id }))
            setPatientData(patient_data)

            reset(patient_data)
        }
    }, [props.data])

    const regions = useSelector(state => state.regions).data
    const languages = useSelector(state => state.languages).data
    const religions = useSelector(state => state.religions).data
    const classifications = useSelector(state => state.classifications).data
    const countries = useSelector(state => state.countries).data

    useEffect(() => {
        if (regions.length === 0) {
            dispatch(fetchRegions())
        }
        if (languages.length === 0) {
            dispatch(fetchLanguages())
        }
        if (religions.length === 0) {
            dispatch(fetchReligions())
        }
        if (classifications.length === 0) {
            dispatch(fetchMedicalClassifications())
        }
        if (countries.length === 0) {
            dispatch(fetchCountries())
        }
    }, [])


    const onSubmit = (data) => {
        props.onSubmit(data)
    }

    const onError = (errors, e) => {
        console.error(errors);
        showSnackbar('Please fill all required fields', 'error')
    };



    if (!patientData) {
        return <Box></Box>
    }
    const get_avatar = () => {
        if (watch('avatar_before')) {
            return watch('avatar_before')
        } else if (watch('avatar_after')) {
            return watch('avatar_after')
        }
        return null
    }

    const onChangeVal = (e) => {
        // set to react-hook-form
        setValue('avatar_before', e.thumbnailLink)
    }
    
    return (
        <Box>
            <SnackbarComponent />
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, mb: 3 }}>
                    <Box sx={{
                        width: '72px', height: '72px', borderRadius: '50%',
                        backgroundColor: "#EEEEEF", justifyContent: 'center', display: 'flex'
                        //backgroundColor: 'rgb(168, 170, 174)'
                    }}>
                        {get_avatar() ?
                            <Avatar src={get_avatar()} sx={{ width: '72px', height: '72px' }} />
                            :
                            <PersonIcon sx={{ width: '52px', height: '52px', color: 'rgb(168, 170, 174)', mt: 1 }} />
                        }
                    </Box>

                    <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <EditAvatar data={props.data} onChangeVal={onChangeVal} patient_id={patientData.id}
                        drive_id={patientData.drive_id} />
                        
                    </Box>
                </Box>
                <Box sx={{ mb: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <CustomInputLabel>First Name</CustomInputLabel>
                            <TextField size="small" {...register('first_name', { required: true })} name="first_name" error={Boolean(errors.first_name || watch("first_name") === "")} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Last Name</CustomInputLabel>
                            <TextField size="small" {...register('last_name', { required: true })} name="last_name" error={Boolean(errors.last_name || watch("last_name") === "")} />
                        </Grid>
                        {/* <Grid item md={2}>
                            <CustomInputLabel>Alias</CustomInputLabel>
                            <TextField size="small" {...register('alias', { required: true })} name="alias" />
                        </Grid> */}
                        <Grid item md={3}>
                            <CustomInputLabel>Contact number (eg. Whatsapp no.)</CustomInputLabel>
                            <TextField size="small" {...register('whatsapp_number')} 
                            name="whatsapp_number" />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={2}>
                            <CustomInputLabel>Date of Birth</CustomInputLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker format={DATE_FORMAT} onChange={(e) => setValue("dob", formatDate(e.$d, DATE_FORMAT))} name="dob" slotProps={{ textField: { size: 'small' } }} defaultValue={watch('dob') ? dayjs(watch('dob'), DATE_FORMAT) : null} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Estimated DOB</CustomInputLabel>
                            <TextField size="small" {...register('dob_text')} name="dob_text" placeholder="when dob unknown" />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Alias</CustomInputLabel>
                            <TextField size="small" {...register('alias')} name="alias" />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Gender</CustomInputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="gender"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...field} // spread the field props into the Select component
                                        >
                                            <MenuItem value={'Male'}>Male</MenuItem>
                                            <MenuItem value={'Female'}>Female</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Height (cm)</CustomInputLabel>
                            <TextField size="small" {...register('height')} name="height" type="text" />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Weight (kg)</CustomInputLabel>
                            <TextField size="small" {...register('weight')} name="weight" type="text" />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box sx={{ display: 'flex' }}>
                                <Controller
                                    name="has_birth_certificate"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            id="has_birth_certificate"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            color="primary"
                                        />
                                    )}
                                />
                                <InputLabel htmlFor="has_birth_certificate" sx={{ fontSize: '13px', mt: 1.5 }}>Has Birth Certificate</InputLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item md={2}>
                            <CustomInputLabel>Birth Country</CustomInputLabel>
                            <Controller
                                name="country"
                                control={control}
                                defaultValue={null}
                                rules={{ required: 'Country is required!' }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        options={countries.map(({ id, name }) => ({ label: name, value: id }))}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                                error={Boolean(errors.country) || !watch('country')}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Citizenship</CustomInputLabel>
                            <Controller
                                name="citizenship"
                                control={control}
                                defaultValue={null}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        options={countries.map(({ id, name }) => ({ label: name, value: id }))}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Religion</CustomInputLabel>
                            <Controller
                                name="religion"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        options={religions.map(({ id, name }) => ({ label: name, value: id }))}
                                        //getOptionLabel={(option) => console.log(option)}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" variant="outlined" fullWidth />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Primary Region</CustomInputLabel>
                            <Controller
                                name="region"
                                control={control}
                                defaultValue={null}
                                rules={{ required: 'Region is required!' }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        options={regions.map(({ id, name }) => ({ label: name, value: id }))}
                                        //getOptionLabel={(option) => option.title}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" variant="outlined" fullWidth error={Boolean(errors.region || !watch("region"))} />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Medical Classification</CustomInputLabel>
                            <Controller
                                name="medical_classification"
                                control={control}
                                defaultValue={null}
                                rules={{ required: 'Medical classification is required!' }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        options={classifications.map(({ id, name }) => ({ label: name, value: id }))}
                                        //getOptionLabel={(option) => option.title}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" variant="outlined" fullWidth
                                                error={Boolean(errors.medical_classification || !watch("medical_classification"))} />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2} columns={10}>
                        <Grid item md={6}>
                            <CustomInputLabel>Languages Spoken</CustomInputLabel>
                            <Controller
                                name="languages"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        multiple
                                        options={languages.map(({ id, name }) => ({ label: name, value: id }))}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip
                                                    label={option.label}
                                                    {...getTagProps({ index })}
                                                    size="small"
                                                />
                                            ))
                                        }
                                        getOptionLabel={(option) => option.label}
                                        value={value}
                                        onChange={(_, newValue) => onChange(newValue)}
                                        renderInput={(params) => (
                                            <TextField {...params} size="small" variant="outlined" fullWidth />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <CustomInputLabel>&nbsp;</CustomInputLabel>
                            <Box sx={{ display: 'flex' }}>
                                <Controller
                                    name="understands_english"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            id="understands_english"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            color="primary"
                                        />
                                    )}
                                />
                                <InputLabel htmlFor="understands_english" sx={{ fontSize: '13px', mt: 1.5 }}>Understands English</InputLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CustomInputLabel>Contact Details</CustomInputLabel>
                    <TextField
                        id="outlined-multiline-flexible"
                        multiline
                        rows={4}
                        {...register('address')} name="address"
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                </Box>
                <PatientAction>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: '10px' }}>
                        <Button disabled={apiReq.loading} onClick={props.onCancel} variant="outlined">Cancel</Button>
                        <Button type="submit" disabled={apiReq.loading} variant="contained">
                            {apiReq.loading ? 'Saving' : 'SAVE'}
                        </Button>
                    </Box>
                </PatientAction>
            </form>
        </Box>
    )
}