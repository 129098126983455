import { Button, Box, Stack, Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import GeneralDialog from "../../../../components/Dialogs/CustomDialog";
import instance from "../../../../auth/utils/useJwt";
import Autocomplete from '@mui/material/Autocomplete';

export default function AssignGroup({ user_data, userGroups, setUserGroups, onGroupChange }) {
    const { email } = user_data;
    const [open, setOpen] = useState(false);
    const [allGroups, setAllGroups] = useState([]);
    const [loading, setLoading] = useState(true);

    const [copyUserGroups, setCopyUserGroups] = useState([]);

    const getGroups = () => {
        // Fetch all groups
        instance.get("/admin/groups").then(res => {
            setAllGroups(res.data);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    };

    useEffect(() => {
        getGroups();
    }, []);


    useEffect(() => {
        setCopyUserGroups(userGroups);
    }, [userGroups]);

    const handleGroupChange = (event, newValue) => {
        newValue.indx = copyUserGroups.length + 1;
        setCopyUserGroups([...copyUserGroups, ...[newValue]]);
    };

    const onAssignClick = () => {
        // Assign groups
        setLoading(true);
        instance.post("/admin/groups", { user_email: email, groups: copyUserGroups.map(g => g.id) }).then(res => {
            setUserGroups(copyUserGroups);
            onGroupChange();
            setLoading(false);
            setOpen(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    };

    return (
        <>
            <Button size="small" variant="outlined" onClick={() => setOpen(true)}>Assign Group</Button>
            <GeneralDialog
                open={open} handleClose={() => setOpen(false)}
                title="Assign Group"
                actions={
                    <Stack direction="row" spacing={1}>
                        <Button variant="outlined" size="small" onClick={() => setOpen(false)}
                            disabled={loading}
                        >Cancel</Button>
                        <Button variant="contained" size="small" onClick={onAssignClick} disabled={loading}>
                            {loading ? "Processing..." : "Assign"}
                        </Button>
                    </Stack>
                }
            >
                <Box>
                    <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                        {copyUserGroups?.map(group => (
                            <Chip sx={{ mb: 1 }}
                                key={group.id}
                                label={group.name}
                                onDelete={() => setCopyUserGroups(copyUserGroups?.filter(g => g.id !== group.id))}
                            />
                        ))}
                    </Stack>

                    <Box sx={{ mt: 2 }}>
                        <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={allGroups.filter(group => !copyUserGroups.some(selectedGroup => selectedGroup.id === group.id))}
                            getOptionLabel={(option) => option.name}
                            filterSelectedOptions
                            size="small"
                            onChange={handleGroupChange}
                            value={[]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Search and select groups"
                                />
                            )}
                        />
                    </Box>
                </Box>
            </GeneralDialog>
        </>
    );
}
