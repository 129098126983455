import React, { useState, useEffect } from "react";
import AppLayout from "../../../components/layout/AppLayout";
import { Box, Typography, Grid, Card, CardHeader, CardContent, LinearProgress } from "@mui/material";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import instance from "../../../auth/utils/useJwt";
import { Link, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import TableOfContent from "./TableOfContent";
import FeedbackSubmission from "./FeedbackSubmission";

export default function TrainingDetails() {

    const [training, setTraining] = useState(null)
    const [loading, setLoading] = useState(true)

    const { folder, slug } = useParams()

    const fetchTraining = async () => {
        setLoading(true)
        instance.get(`/training?folder=${folder}&slug=${slug}`).then(res => {
            setLoading(false)

            //assign each h2 heading an id
            const domParser = new DOMParser();
            const doc = domParser.parseFromString(res.data.content, 'text/html');
            const headings = doc.querySelectorAll('h2');
            headings.forEach((heading, index) => {
                heading.id = `heading-ehr-${index}`
            })

            res.data.content = doc.body.innerHTML

            setTraining(res.data)
            console.log(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTraining()
    }, [])

    useEffect(() => {
        if (training) {
            instance.post(`/training/progress`, {
                feedback: null,
                training_id: training.id
            }).then(res => {
                console.log(res)
            }).catch(err => {

            })
        }
    }, [training])

    const onClickHeading = (index, heading) => {
        // id

        const toMoveId = `heading-ehr-${index}`
        const element = document.getElementById(toMoveId)
        element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        })
    }



    return (
        <AppLayout title="Training" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Training", url: "/training" },
                    { text: `${folder} Training` }
                ]} />
            </BContainer>
            <Box sx={{ px: 2, py: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                        <Card>
                            {loading && <LinearProgress />}
                            {training && (
                                <Box>

                                    <Box sx={{ borderBottom: '1px solid #ddd', px: 2, py: 1 }}>
                                        <Typography variant="h6">{training.title}</Typography>
                                        <Typography variant="subtitle2" style={{
                                            color: "#999"
                                        }}>{training.reading_minutes} reading minutes</Typography>
                                    </Box>
                                    <CardContent>

                                        {
                                            parse(training.content)
                                        }

                                    </CardContent>
                                </Box>
                            )}

                        </Card>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TableOfContent training_content={training?.content} onClickHeading={onClickHeading} />
                        {training?.related_trainings?.length > 0 &&
                            <Card sx={{ mb: 2 }}>
                                {/* <CardHeader title="Related Training" /> */}
                                <Box sx={{
                                    borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
                                }}>Related Training</Box>
                                <CardContent>
                                    {
                                        training?.related_trainings?.map((item, index) => (
                                            <Link to={`/training/${item.folder}/${item.slug}`} key={index}>
                                                <Typography variant="subtitle2" style={{ display: "block", padding: "5px 0" }}>{item.title}</Typography>
                                            </Link>
                                        ))
                                    }
                                </CardContent>
                            </Card>
                        }

                        <FeedbackSubmission training_id={training?.id} />
                    </Grid>
                </Grid>

            </Box>
        </AppLayout>
    )
}