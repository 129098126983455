import { Box, Card, CardContent, CardHeader, ListItem, ListItemText, Typography } from "@mui/material";

export default function TableOfContent({ training_content, onClickHeading }) {
    const extractHeadings = (htmlContent) => {
        const domParser = new DOMParser();
        const doc = domParser.parseFromString(htmlContent, 'text/html');
        const headings = doc.querySelectorAll('h2');

        // Extract text from each h2 heading
        return Array.from(headings).map(heading => heading.textContent);
    };
    const headings = extractHeadings(training_content);

    if (!headings.length) return null;
    return (
        <Card sx={{
            mb: 2
        }}>
            {/* <Typography variant="h6">Table of Content</Typography> */}
            <Box sx={{
                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
            }}>Table of Content</Box>
            <CardContent>
                {headings.map((heading, index) => (
                    <Typography variant="subtitle2"
                    onClick={() => 
                        onClickHeading(index, heading)
                    }
                    style={{ display: "block", padding: "5px 0", color: "#06f", 
                        borderBottom: index === headings.length - 1 ? "none" : "1px solid #ddd",
                        cursor: "pointer"
                    }}>{heading}</Typography>
                ))}
            </CardContent>
        </Card>
    );

}