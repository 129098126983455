import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../auth/utils/useJwt';

// Creating the async thunk for fetching patients
export const fetchCase = createAsyncThunk('patients/fetchCase', async (payload) => {
    try {
        const response = await instance.get(`/cases?id=${payload.patient_id}&case_id=${payload.case_id}`);  // Changed API endpoint to '/case'
        return response.data; // API response is an object for case
    } catch (error) {
        throw error;
    }
});

// Creating the slice for case
export const Case = createSlice({
    name: 'case',
    initialState: {
        data: null,
        loading: true,
        error: null,
        is_readonly: false
    },
    reducers: {
        setCaseData: (state, action) => {
            state.data = action.payload
        },
        setCaseValue: (state, action) => {
            state.data[action.payload.name] = action.payload.value
        },
        setCaseReadonly: (state, action) => {
            state.is_readonly = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCase.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCase.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchCase.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { setCaseData, setCaseValue, setCaseReadonly } = Case.actions;

export default Case.reducer;

