import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserDetails from './AllTabs/UserDetails';
import Security from './AllTabs/Security';
import Groups from './AllTabs/Groups';
import Meta from './AllTabs/Meta';
import Permissions from './AllTabs/Permissions';
import Regions from './AllTabs/Regions';
import UserTrainings from './AllTabs/Trainings';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function DetailsTabs(props) {
    //const [value, setValue] = React.useState(0);
    const tabData = [
        { label: 'User Details', component: <UserDetails data={props.data} /> },
        { label: "Permissions", component: <Permissions id={props.data?.id} /> },
        { label: "Regions", component: <Regions id={props.data?.id} /> },
        // { label: "Trainings", component: <UserTrainings id={props.data?.id} /> },
        { label: 'Groups', component: <Groups data={props.data} /> },
        //{ label: 'Meta', component: <Meta id={props.patient_id} /> },
    ]

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Patient Details">
                    {
                        tabData.map((item, index) => (
                            <Tab key={`tab-label-${index}`} label={item.label} {...a11yProps(index)} sx={{ textTransform: 'capitalize' }} />
                        ))
                    }

                </Tabs>
            </Box>
            {
                tabData.map((item, index) => (
                    <TabPanel key={`tab-${index}`} value={value} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </Box>
    );
}
