import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import OverrideStatus from './dialogs/OverrideStatus';
import OverrideClassification from './dialogs/OverrideClassification';
import useSnackbar from "../../../components/Dialogs/SnackBar";
import DeleteCaseDialog from './dialogs/DeleteCase';
import { useSelector } from 'react-redux';
import { IsCaseAdmin } from '../../../redux/user_roles';


export default function CaseMenu(props) {


  const {is_readonly} = useSelector(state => state.patient_case)
  const IsCaseAdminPermission = useSelector(IsCaseAdmin)
  const [SnackbarComponent, showSnackbar] = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [overrideStatusOpen, setOverrideStatusOpen] = React.useState(false)
  const [overrideClassificationOpen, setOverrideClassificationOpen] = React.useState(false)
  const [deleteCaseOpen, setDeleteCaseOpen] = React.useState(false)
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickClose = (dt) => {
    if (dt === "override_status") {
      setOverrideStatusOpen(true)
    } else if (dt === "override_classification") {
      setOverrideClassificationOpen(true)
    } else if (dt === "delete_case") {
      setDeleteCaseOpen(true)
    }
    handleClose()
  }


  return (
    <React.Fragment>
      <SnackbarComponent />
      <OverrideStatus open={overrideStatusOpen} setOpen={setOverrideStatusOpen} patientData={props.data} showSnackbar={showSnackbar} />
      <OverrideClassification open={overrideClassificationOpen} setOpen={setOverrideClassificationOpen}
        patientData={props.data} showSnackbar={showSnackbar} />
      <DeleteCaseDialog open={deleteCaseOpen} setOpen={setDeleteCaseOpen} patientData={props.data} showSnackbar={showSnackbar} />
      {!is_readonly &&
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title="Patient Menu">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleClickClose("override_status")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Override Status
        </MenuItem>
        <MenuItem onClick={() => handleClickClose("override_classification")}>
          <ListItemIcon>
            <BuildIcon fontSize="small" />
          </ListItemIcon>
          Overide Classification
        </MenuItem>
        {IsCaseAdminPermission &&
          <MenuItem onClick={() => handleClickClose("delete_case")}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete Case
          </MenuItem>
        }
      </Menu>
    </React.Fragment>
  );
}
