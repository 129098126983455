// ** React Imports
import { Navigate, Outlet } from "react-router-dom"
import { isUserLoggedIn } from "../auth/utils" 
import { useSelector } from "react-redux"
import { CanAccessAdmin } from "../redux/user_roles"

const AdminRoute = (props) => {
const user = useSelector(state => state.user)
  const { roles } = user
  const role_names = roles.names

  const CanAccessAdminPermission = useSelector(CanAccessAdmin)

  const userLoggedIn = isUserLoggedIn()
  if (userLoggedIn) {
    if (!CanAccessAdminPermission) {
        return <Navigate to={"/"} />
    }
    return <Outlet />
  }else{
    return <Navigate to={"/login"} />
  }
}


export default AdminRoute