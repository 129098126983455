import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { IconButton, Stack, Typography } from "@mui/material";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";


const DataBox = styled(Box)({
    border: '1px solid #ddd',
    padding: '2px 10px'
});


function ExpandableList({ title, isOpen, icon, content, activeTrainingId, setActiveTrainingId }) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const onItemClick = (id, index) => {
        const currentItemIndex = content.findIndex(c => c.id === id);
        // If it is previous item, just set it to active
        if (currentItemIndex < index) {
            setActiveTrainingId(id);
            return;
        }

        // If it is next item, check if that item is completed
        if (currentItemIndex > index) {
            if (content[currentItemIndex].completed) {
                setActiveTrainingId(id);
            }
        }
    }



    return (
        <Box>
            <DataBox>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ flexGrow: 1 }}>
                        {icon}
                        <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                            {title}
                        </Typography>
                    </Stack>

                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Stack>
            </DataBox>

            {/* Animated content */}
            <Box
                sx={{
                    overflow: "hidden", 
                    maxHeight: open ? 500 : 0, // Adjust maxHeight as needed
                    transition: "max-height 0.3s ease-in-out", // Smooth transition
                }}
            >
                <Box sx={{ p: 1 }}>
                    {content.map((c, index) => (
                        <Box
                            key={index}
                            sx={{
                                py: 0.3,
                                cursor: 'pointer',
                                color: activeTrainingId === c.id ? "#f22c3d" : "black",
                                "&:hover": {
                                    color: "#f22c3d",
                                    backgroundColor: "#f9f9f9"
                                }
                            }}
                            onClick={() => onItemClick(c.id, index)}
                        >
                            {c.title}
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}



export { DataBox, ExpandableList };