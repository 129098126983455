import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Acceptance from './Tabs/Acceptance';
import Memos from './Tabs/Memos';
import Audit from './Tabs/Audit';
import Documents from './Tabs/Documents';
import Logistics from './Tabs/Logistics';
import Checklist from './Tabs/Checklist';
import Expenditure from './Tabs/Expenditure';
import XeroExpenditure from './Tabs/XeroExpenditure/XeroExpenditure';

import { hasXeroAccess } from '../../../redux/user';
import { useSelector } from 'react-redux';
import { CanViewExpenses } from '../../../redux/user_roles';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ py: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function CaseTabs(props) {
    const [value, setValue] = React.useState(0);

    // Get query param
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab') || "acceptance"

    React.useEffect(() => {
        if (tab && tab==="expenses") {
            setValue(1)
        }else if(tab){
            const index = tabData.findIndex(item => item.label.toLowerCase() === tab)
            if(index !== -1){
                setValue(index)
            }
        }
    }, [tab])

    const xeroAccess = useSelector(CanViewExpenses);

    

    const tabData = xeroAccess ? [
        { label: 'Acceptance', component: <Acceptance /> },
        // { label: 'Expenditure', component: <Expenditure /> },
        { label: 'Expenses', component: <XeroExpenditure /> },
        { label: 'Checklist', component: <Checklist /> },
        { label: 'Logistics', component: <Logistics /> },
        { label: 'Documents', component: <Documents patient_id={props.patient_id} /> },
        { label: 'Memos', component: <Memos patient_id={props.patient_id}/> },
        { label: 'Audit', component: <Audit  patient_id={props.patient_id} /> }
    ] : [
        { label: 'Acceptance', component: <Acceptance /> },
        { label: 'Expenditure', component: <Expenditure /> },
        { label: 'Checklist', component: <Checklist /> },
        { label: 'Logistics', component: <Logistics /> },
        { label: 'Documents', component: <Documents patient_id={props.patient_id} /> },
        { label: 'Memos', component: <Memos patient_id={props.patient_id}/> },
        { label: 'Audit', component: <Audit  patient_id={props.patient_id} /> }
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.setTab(newValue)

        // Set lowercase tab label as query param
        const tab = tabData[newValue].label.toLowerCase()
        const url = new URL(window.location.href)
        url.searchParams.set('tab', tab)
        window.history.pushState({}, '', url)
    };


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Patient Details">
                    {
                        tabData.map((item, index) => (
                            <Tab key={`tab-label-${index}`} label={item.label} {...a11yProps(index)} sx={{ textTransform: 'capitalize' }} />
                        ))
                    }

                </Tabs>
            </Box>
            {
                tabData.map((item, index) => (
                    <TabPanel key={`tab-${index}`} value={value} index={index}>
                        {item.component}
                    </TabPanel>
                ))
            }
        </Box>
    );
}
