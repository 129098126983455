import { Alert, Card } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import DetailsCard from "./DetailsCard";
import PatientTabs from "./Tabs";
import PatientMenu from "./DropMenu";
import instance from "../../../auth/utils/useJwt";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import ReferralActionButtons from "./ReferralActionButtons";
import ReferralDetails from "./ReferralDetails";
import { useSelector } from "react-redux";
import { CanModifyPatient } from "../../../redux/user_roles";

export default function PatientDetails() {
    const [patientData, setPatientData] = useState(null)
    const { patient_id } = useParams()
    const [apiReq, setApiReq] = useState({ loading: true, error: null })
    const CanModifyPatientPermission = useSelector(CanModifyPatient)
    useEffect(() => {
        instance.get(`/patients?id=${patient_id}`).then(res => {
            setApiReq({ loading: false, error: null })
            setPatientData(res.data)
        }).catch(err => {
            setApiReq({ loading: false, error: "Unable to fetch patient data" })
            console.log(err)
        })
    }, [])

    const breadcrumbs_v = [
        patient_id.slice(0, 1) === "P" ? { url: "/referrals", text: "Referrals" } : { url: "/patients", text: "Patients" },
        { text: `#${patient_id}` }
    ]


    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />
                {patientData &&
                    <>

                        {patient_id.slice(0, 1) === "R" ?
                            <PatientMenu patient_id={patient_id} romac_id={patientData.romac_id} id={patientData.id} archived={patientData.archived} />
                            :
                            <ReferralActionButtons patient_id={patient_id} romac_id={patientData.romac_id} id={patientData.id} archived={patientData.archived} />
                        }
                    </>
                }

            </BContainer>
            {
                !CanModifyPatientPermission &&
                <Alert severity="info" sx={{ m: 2 }}>
                    You have view only access to this patient.
                </Alert>
            }
            <Box sx={{ px: 3, mb: 2 }}>
                {apiReq.loading
                    ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box>
                        {apiReq.error ? <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                            <Typography variant="h6">Patient not found</Typography>
                        </Box> :
                            <Box>
                                { patient_id?.slice(0, 1) === "P" ? <ReferralDetails data={patientData}
                                patient_id={patientData.id}
                                /> :
                                <Box>
                                <Card>

                                    <Box sx={{ px: 2 }}>
                                        <DetailsCard data={patientData} />
                                    </Box>
                                </Card>

                                <Card sx={{ mt: 3, p: 2 }}>
                                    <PatientTabs romac_id={patient_id} patient_id={patientData.id} data={patientData} />
                                </Card>
                                </Box>
}
                            </Box>}
                    </Box>}
            </Box>
        </AppLayout>
    )
}