import * as React from 'react';
import { ListItemButton, Button, Stack, Typography, List, ListItem, ListItemIcon, ListItemText, Box, TextField, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import instance from '../../../../../auth/utils/useJwt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import InfoIcon from '@mui/icons-material/Info';
import AppsIcon from '@mui/icons-material/Apps';
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';
import { useNavigate } from 'react-router-dom';
import CustomInputLabel from '../../../../../components/forms/CustomInputLabel';
import useSnackbar from '../../../../../components/Dialogs/SnackBar';
export default function ResetUserPassword({
    id
}) {
    const [apiReq, setApiReq] = React.useState({
        open: false, processing: false
    })
    const [selectedOption, setSelectedOption] = React.useState('reset_password'); // Default selection


    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState(null)
    const navigate = useNavigate()
    const onAction = () => {
        if (password.length < 8) {
            setError("Password must be at least 8 characters long.")
            return
        }
        setApiReq({ ...apiReq, processing: true })
        instance.patch("/admin/google-admin/user", {
            "id": id,
            "request_type": "reset_password",
            "password": password
        }).then(res => {
            showSnackbar('Password reset successfully', 'success')
            setApiReq({ open: false, processing: false })
        }).catch(err => {
            setError("An error occurred. Please try again.")
            setApiReq({ open: false, processing: true })

        })
    }

    React.useEffect(() => {
        if (password.length > 0) {
            setError(null)
        }
    }, [password])

    const handleClose = () => {
        setApiReq({ open: false, processing: false })
    }
    const onRadioChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'reset_password') {
            setPassword('')
        }
    }

    return (
        <>
            <SnackbarComponent />
            <ListItemButton onClick={() => {
                setApiReq({ open: true, processing: false })
            }}>
                Reset Password
            </ListItemButton>

            <GeneralDialog open={apiReq.open}
                title={`Reset User Password`} handleClose={handleClose}
                actions={
                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleClose} variant="outlined" color="error">Cancel</Button>
                        <Button onClick={onAction} variant="contained" color="primary" autoFocus disabled={apiReq.processing}>
                            {apiReq.processing ? 'Processing...' : 'Reset Password'}
                        </Button>
                    </Stack>
                }>
                <Box>
                    <Stack direction="row" alignItems="center" alignContent="center">
                        <FormControlLabel
                            control={
                                <Radio
                                    name='generate'
                                    value="reset_password"
                                    checked={selectedOption === 'reset_password'}
                                    onChange={onRadioChange}
                                />
                            }
                            label="Automatically generate a new password"
                            sx={{ mt: 0.5 }}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" alignContent="center">
                        <FormControlLabel
                            control={
                                <Radio
                                    name='generate'
                                    value="custom_password"
                                    checked={selectedOption === 'custom_password'}
                                    onChange={onRadioChange}
                                />
                            }
                            label="Custom password"
                            sx={{ mt: 0.5 }}
                        />
                    </Stack>

                    {selectedOption === 'custom_password' &&
                        <Box>

                            <CustomInputLabel>
                                New Password
                            </CustomInputLabel>
                            <TextField size='small' fullWidth variant='outlined' type='password'
                                value={password} onChange={(e) => setPassword(e.target.value)}
                                error={error ? true : false} helperText={error}

                            />
                        </Box>
                    }
                </Box>

            </GeneralDialog>

        </>

    );
}



