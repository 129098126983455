import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, Grid, Checkbox, Button, Stack } from '@mui/material';
import MenuItem from "@mui/material/MenuItem";
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDispatch, useSelector } from 'react-redux';
import { fetchReligions } from '../../../../redux/general/religion';
import { fetchOccupations } from '../../../../redux/general/occupation';
import { fetchLanguages } from '../../../../redux/general/language';
import MUIAutocompleteMultiple from '../../../../components/forms/MUIAutocompleteMultiple';
import MUIAutocomplete from '../../../../components/forms/MUIAutocomplete';
import DatePicker from '../../../../components/forms/DatePicker';
import CustomInputLabel from '../../../../components/forms/CustomInputLabel';
import GeneralDialog from '../../../../components/Dialogs/CustomDialog';
import PatientAction from '../../../../components/PermissionsWrappers/PatientAction';

const relationships = [
  { "value": 0, "label": "MOTHER" },
  { "value": 1, "label": "FATHER" },
  { "value": 2, "label": "AUNT" },
  { "value": 3, "label": "UNCLE" },
  { "value": 4, "label": "SISTER" },
  { "value": 5, "label": "BROTHER" },
  { "value": 6, "label": "GRANDMOTHER" },
  { "value": 7, "label": "GRANDFATHER" },
  { "value": 8, "label": "GUARDIAN" },
  { "value": 9, "label": "COUSIN" },
  { "value": 10, "label": "FRIEND" },
  { "value": 11, "label": "OTHER" }
]

export const relationship_map = {
  0: "MOTHER",
  1: "FATHER",
  2: "AUNT",
  3: "UNCLE",
  4: "SISTER",
  5: "BROTHER",
  6: "GRANDMOTHER",
  7: "GRANDFATHER",
  8: "GUARDIAN",
  9: "COUSIN",
  10: "FRIEND",
  11: "OTHER"
}



export default function AddEditGuardian(props) {
  const { apiReq, open, handleClose} = props
  const { register, reset, control, handleSubmit, watch, setValue,
    formState: { errors },
  } = useForm({
    languages: []
  });
  const dispatch = useDispatch()
  const religions = useSelector(state => state.religions).data
  const occupations = useSelector(state => state.occupations).data
  const languages = useSelector(state => state.languages).data

  useEffect(() => {
    if (props?.data) {
      var selectedData = props.data
      console.log(selectedData)
      selectedData.relationship = relationship_map[selectedData.relationship]
      selectedData.understands_english = selectedData.understands_english === "Yes" ? true : false
      selectedData.languages = selectedData.languages.map(({ id, name }) => ({ label: name, value: id }))
      console.log(selectedData)
      reset(props.data)
    }else{
      reset({
        languages: [],
        first_name: "",
        last_name: "",
        dob: null,
        dob_text: "",
        gender: "",
        relationship: "",
        religion: "",
        occupation: "",
        alias: "",
        passport_number: "",
        passport_expiry: "",
        email: "",
        mobile: "",
        home_phone: "",
        address: "",
        understands_english: null
      })
    }
  }, [props?.data])


  useEffect(() => {
    if (religions.length === 0) {
      dispatch(fetchReligions())
    }
    if (occupations.length === 0) {
      dispatch(fetchOccupations())
    }
    if (languages.length === 0) {
      dispatch(fetchLanguages())
    }
  }, [])



  const onSubmit = (data) => {
    props.onSubmit(data)
  }

  const onError = () => {
    console.log("Error")
  }


  return (
    <GeneralDialog open={open} handleClose={handleClose} title={props?.data ? 'Edit Guardian' : 'Add Guardian'}
    
      maxWidth="lg"
      disabled={apiReq.loading}
      onFormSubmit={handleSubmit(onSubmit, onError)}
      actions={
        <Stack direction="row" spacing={2}>
          <Button onClick={handleClose} disabled={apiReq.loading} variant="outlined">Cancel</Button>
          <PatientAction>
          <Button type="submit" disabled={apiReq.loading} variant="contained">
            {
              apiReq.loading ? `Saving` : `SAVE`
            }
          </Button>
          </PatientAction>
        </Stack>
      }
    >

        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} columns={10}>

              <Grid item md={2}>
                <CustomInputLabel>First Name</CustomInputLabel>
                <TextField size="small" {...register('first_name', { required: true })} fullWidth name="first_name" error={Boolean(errors.first_name || watch("first_name") === "")} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Last Name</CustomInputLabel>
                <TextField size="small" {...register('last_name', { required: true })} fullWidth name="last_name" error={Boolean(errors.last_name || watch("last_name") === "")} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Date of Birth</CustomInputLabel>
                <DatePicker name="dob" value={watch("dob")} onChange={(e) => setValue("dob", e.value)} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Estimated DOB</CustomInputLabel>
                <TextField size="small" {...register('dob_text')} name="dob_text" placeholder="when dob unknown" fullWidth />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Alias</CustomInputLabel>
                <TextField size="small" {...register('alias')} name="alias" fullWidth />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Gender</CustomInputLabel>
                <FormControl fullWidth>
                  <Controller
                    name="gender"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Relationship is required!' }}
                    render={({ field }) => (
                      <Select
                        size="small"
                        error={Boolean(errors.gender) || !watch('gender')}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        {...field} // spread the field props into the Select component
                      >
                        <MenuItem value={'Male'}>Male</MenuItem>
                        <MenuItem value={'Female'}>Female</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>



              <Grid item md={2}>
                <CustomInputLabel>Religion</CustomInputLabel>

                <MUIAutocomplete name="religion" errors={errors} control={control}
                  options={religions.map(({ id, name }) => ({ label: name, value: id }))} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Occupation</CustomInputLabel>

                <MUIAutocomplete name="occupation" errors={errors} control={control}
                  options={occupations.map(({ id, name }) => ({ label: name, value: id }))} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Passport Number</CustomInputLabel>
                <TextField size="small" fullWidth {...register('passport_number')} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Passport Expiry</CustomInputLabel>
                <TextField size="small" fullWidth {...register('passport_expiry')} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0.5 }} columns={8}>
              <Grid item md={2}>
                <CustomInputLabel>Relationship to Patient</CustomInputLabel>

                <MUIAutocomplete required name="relationship" errors={errors} control={control}
                  options={relationships} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Email</CustomInputLabel>
                <TextField size="small" fullWidth {...register('email')} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Mobile</CustomInputLabel>
                <TextField size="small" fullWidth {...register('mobile')} />
              </Grid>
              <Grid item md={2}>
                <CustomInputLabel>Phone Number</CustomInputLabel>
                <TextField size="small" fullWidth {...register('home_phone')} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 0.5 }}>
              {languages?.length > 0 &&
                <Grid item md={6}>
                  <CustomInputLabel>Languages Spoken</CustomInputLabel>

                  <MUIAutocompleteMultiple name="languages" errors={errors} control={control}
                    options={languages.map(({ id, name }) => ({ label: name, value: id }))} />

                </Grid>
              }
              <Grid item>
                <CustomInputLabel>&nbsp;</CustomInputLabel>
                <Box sx={{ display: 'flex' }}>
                  <Controller
                    name="understands_english"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        id="understands_english"
                        onChange={(e) => field.onChange(e.target.checked)}
                        color="primary"
                      />
                    )}
                  />
                  <CustomInputLabel sx={{ mt: 1.5 }}>Understands English</CustomInputLabel>
                </Box>
              </Grid>
              <Grid item md={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                <CustomInputLabel>Residential Address</CustomInputLabel>
                <TextField size="small" rows={4} multiline {...register('address')} />
              </Grid>
            </Grid>

        </Box>
    </GeneralDialog>
          
  )
}

