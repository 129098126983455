
import { useEffect, useState } from "react";
import { Card, CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import EditForm from "./EditForm";
import { useSelector } from "react-redux";
import instance from "../../../auth/utils/useJwt";
import { useNavigate, useParams } from "react-router-dom";
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import useSnackbar from "../../../components/Dialogs/SnackBar";
import BContainer from "../../../components/BContainer";
import { CanModifyPatient } from "../../../redux/user_roles";

export default function PatientEdit() {


    const [patientData, setPatientData] = useState(null)
    const { patient_id } = useParams()
    const [apiReq, setApiReq] = useState({ loading: true, error: null })
    
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const navigate = useNavigate()
    const canModifyPatientData = useSelector(CanModifyPatient)

    useEffect(() => {
        instance.get(`/patients?id=${patient_id}`).then(res => {
            setApiReq({ loading: false, error: null })
            setPatientData(res.data)
        }).catch(err => {
            setApiReq({ loading: false, error: null })
            console.log(err)
        })
    }, [])

    const [updateApiReq, setUpdateApiReq] = useState({
        loading: false, error: null
    })

    const onSubmit = (data) => {
        setUpdateApiReq({ loading: true, error: null })
        instance.patch("/patients", data).then(res => {
            console.log(res)
            setUpdateApiReq({ loading: false, error: null })
            showSnackbar('Patient updated successfully', 'success')
            setTimeout(() => {
                navigate(`/patients/${patient_id}`);
            }, 2000);
        }).catch(res => {
            console.log(res)
            setUpdateApiReq({ loading: false, error: "Error upading user" })
            showSnackbar('Error updating patient', 'error')
        })
    }

    const onCancel = () => {
        navigate(`/patients/${patient_id}`);
    }

    const onChangeVal = (e) => {
        setPatientData({ ...patientData, [e.target.name]: e.target.value.replace("lh6", "lh3").replace("=s220", "") })
    }

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/patients" sx={{ color: '#111', fontSize: '15px' }}>
            Patients
        </Link>,
        <Link underline="hover" key="1" color="inherit" href={`/patients/${patient_id}`} sx={{ color: '#111', fontSize: '15px' }}>
            #{patient_id}
        </Link>,
        <Typography key="3" color="text.primary" sx={{ color: "#09f", fontSize: '14px' }}>
            Edit
        </Typography>
    ];

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <SnackbarComponent />
            <BContainer>
                <Breadcrumbs separator="|" aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
            {apiReq.loading ?
                <Card>
                    <Box sx={{ display: 'flex', justifyContent: 'center', py: 7 }}>
                        <CircularProgress />
                    </Box>
                </Card>
                :
                <Card>
                
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                        borderBottom: '1px solid #b8bfe3'
                    }}>
                        <Box sx={{ pt: 0.5, px: 1 }}>
                            <Typography variant="h6">Edit Patient</Typography>
                        </Box>
                    </Box>
                    {!canModifyPatientData ? <Box sx={{ display: 'flex', justifyContent: 'center', py: 10 }}>
                        <Typography variant="h6" color={"error"}>You don't have permission to edit patient</Typography>
                    </Box>
                        :
                        <Box sx={{ p: 2 }}>
                            {patientData ?
                                <EditForm data={patientData} apiReq={updateApiReq} onSubmit={onSubmit} onCancel={onCancel} onChangeVal={onChangeVal} />
                                :

                                <Box sx={{ display: 'flex', justifyContent: 'center', py: 10 }}>
                                    <Typography variant="h6" color={"error"}>Patient not found</Typography>
                                </Box>

                            }
                        </Box>
                    }
                </Card>
            }
            </Box>
        </AppLayout>
    )
}