import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, Checkbox } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";
import instance from '../../../../auth/utils/useJwt';
import { styled } from '@mui/system';
import useSnackbar from '../../../../components/Dialogs/SnackBar';
import PatientAction from '../../../../components/PermissionsWrappers/PatientAction';


const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: '13px',
    marginBottom: theme.spacing(0.4),
}));

export default function Background(props) {
    const { register, reset, control, handleSubmit, watch, setValue,
        formState: { errors },
    } = useForm({});
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const [apiReq, setApiReq] = useState({ loading: false, error: null })
    const [loading, setLoading] = useState(true)

    const get_medical = () => {
        instance.get(`/background?id=${props.id}`).then(res => {
            console.log(res)
            reset(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        // On First load fetch data
        get_medical()
    }, [])

    const onSubmit = (data) => {
        data.id = props.id
        setApiReq({ loading: true, error: null })
        instance.patch("/background", data).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            showSnackbar("Data updated successfully", "success")
        }).catch(err => {
            console.log(err)
            setApiReq({ loading: false, error: null })
            showSnackbar("Failed to update data", "error")
        })
    }

    const onError = () => {

    }

    return (
        <Box>
            <SnackbarComponent />
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Box sx={{ mb: 1 }}>
                    <Grid container spacing={2} columns={10}>
                        <Grid item md={2}>
                            <CustomInputLabel>House Size, mxm</CustomInputLabel>
                            <TextField size="small" fullWidth {...register('house_size')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>No. Rooms</CustomInputLabel>
                            <TextField size="small" fullWidth {...register('number_of_rooms')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>No. Occupants</CustomInputLabel>
                            <TextField size="small" fullWidth {...register('number_of_people')} />
                        </Grid>
                    </Grid>
                </Box>
                {/* <Box sx={{ my:2 }}>
                <Grid container spacing={2} columns={5}>
                    <Grid item md={1}>
                        <CustomInputLabel>Siblings</CustomInputLabel>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                            >
                                <MenuItem value={10}>1</MenuItem>
                                <MenuItem value={20}>2</MenuItem>
                                <MenuItem value={30}>3</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={1}>
                        <CustomInputLabel>Gender</CustomInputLabel>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                            >
                                <MenuItem value={10}>Male</MenuItem>
                                <MenuItem value={20}>Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={1}>
                        <CustomInputLabel>Age</CustomInputLabel>
                        <TextField size="small" fullWidth />
                    </Grid>
                    <Grid item md={1}>
                        <CustomInputLabel>First Name</CustomInputLabel>
                        <TextField size="small" fullWidth />
                    </Grid>
                    <Grid item>
                    <CustomInputLabel>&nbsp;</CustomInputLabel>
                        <Button variant="contained">Add Siblings</Button>
                    </Grid>
                </Grid>
            </Box> */}

                <Box sx={{ my: 2 }}>
                    <CustomInputLabel>Age and Gender of Siblings</CustomInputLabel>
                    <TextField rows={3} size="small" {...register('siblings_details')} multiline fullWidth />
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Box sx={{ display: 'flex' }}>
                                <Controller
                                    name="drinking_water"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            id="drinking_water"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            color="primary"
                                        />
                                    )}
                                />
                                <InputLabel sx={{ fontSize: '13px', mt: 1.5 }}>Access to fresh water supply</InputLabel>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box sx={{ display: 'flex' }}>
                                <Controller
                                    name="attends_school"
                                    control={control}
                                    defaultValue={false}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            id="attends_school"
                                            onChange={(e) => field.onChange(e.target.checked)}
                                            color="primary"
                                        />
                                    )}
                                />
                                <InputLabel sx={{ fontSize: '13px', mt: 1.5 }}>Does child attend school</InputLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2} columns={10}>
                        <Grid item md={2}>
                            <CustomInputLabel>Nearest hospital city</CustomInputLabel>
                            <TextField size="small" fullWidth {...register('nearest_city')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Access to transport</CustomInputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="transport"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...field} // spread the field props into the Select component
                                        >
                                            <MenuItem value={0}>Car</MenuItem>
                                            <MenuItem value={1}>Bus</MenuItem>
                                            <MenuItem value={2}>Motor Bike</MenuItem>
                                            <MenuItem value={3}>Walk</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Nearest medical facility, km</CustomInputLabel>
                            <TextField size="small" fullWidth {...register('nearest_medical_km')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Nearest medical facility, hrs</CustomInputLabel>
                            <TextField size="small" fullWidth {...register('nearest_medical_time')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Medical facility served by</CustomInputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="medical_practitioner"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...field} // spread the field props into the Select component
                                        >
                                            <MenuItem value={0}>Doctor</MenuItem>
                                            <MenuItem value={1}>Nurse</MenuItem>
                                            <MenuItem value={2}>Hospital</MenuItem>
                                            <MenuItem value={3}>Regional Hospital</MenuItem>
                                            <MenuItem value={4}>Referring Hospital</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                </Box>
                <PatientAction>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, pt: 2, borderTop: '1px solid #ccc' }}>
                        <Button type='submit' disabled={apiReq.loading} variant="contained">SAVE</Button>
                    </Box>
                </PatientAction>
            </form>
        </Box>
    )
}