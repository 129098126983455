import { useSelector } from "react-redux";
import { CanEditAdmin, IsSuperAdmin } from "../../redux/user_roles";

export default function AdminAction({
    children
}) {
    const CanEditAdminPermission = useSelector(CanEditAdmin)
    return CanEditAdminPermission ? children : null
}

function SuperAdminAction({
    children
}) {
    const IsSuperAdminPermission = useSelector(IsSuperAdmin)
    return IsSuperAdminPermission ? children : null
}

export { SuperAdminAction }