import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { hasWritePermission, hasDeletePermission } from '../../../redux/user';
import { useSelector } from 'react-redux';
import { Button, Stack } from '@mui/material';
import DeletePatientDialog from './components/DeletePatient';
import useSnackbar from '../../../components/Dialogs/SnackBar';
import CheckIcon from '@mui/icons-material/Check';
import AcceptReferralPatientDialog from './components/AcceptReferralPatient';
import { CanModifyReferral } from '../../../redux/user_roles';

export default function ReferralActionButtons(props) {
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    //const canWrite = useSelector(hasWritePermission);
    const canWrite = useSelector(CanModifyReferral);
    const [deletePatient, setDeletePatient] = React.useState(false)
    const [acceptPatient, setAcceptPatient] = React.useState(false)
    const navigate = useNavigate()


    const handleClickCloseDialog = (dt) => {
        if (dt === "delete") {
            setDeletePatient(true)
        }
        else if (dt === "accept") {
            setAcceptPatient(true)
        }
    }

    return (
        <Stack direction="row" spacing={1}>
            <SnackbarComponent />
            {/* <Backdrop open={accepting} sx={{ zIndex: 9999999 }}>
                <CircularProgress color="primary" />
            </Backdrop> */}
            
            <DeletePatientDialog open={deletePatient} setOpen={setDeletePatient} patient_id={props.id} showSnackbar={showSnackbar} />
            <AcceptReferralPatientDialog open={acceptPatient} setOpen={setAcceptPatient} patient_id={props.id} showSnackbar={showSnackbar} />
                {props.romac_id?.slice(0, 1) === "P" && canWrite &&
                    <Button  onClick={() => handleClickCloseDialog("accept")} size="small" startIcon={<CheckIcon />} color="success"
                    sx={{ textTransform: 'capitalize' }}>
                        Accept as new Patient
                    </Button>
                }
               
                {canWrite &&
                    <Button
                        size="small"
                        startIcon={<DeleteIcon />}
                        color="error"
                        onClick={() => handleClickCloseDialog("delete")}
                        sx={{ textTransform: 'capitalize' }}>

                        Delete Referral
                    </Button>
                }
        </Stack>
    );
}
