import { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { Button, LinearProgress } from "@mui/material";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from "@mui/material/MenuItem";

import useSnackbar from '../../../../components/Dialogs/SnackBar';
import instance from "../../../../auth/utils/useJwt";

import CustomInputLabel from "../../../../components/forms/CustomInputLabel";
import PatientAction from "../../../../components/PermissionsWrappers/PatientAction";


export default function Referrer(props) {
    const { register, reset, control, handleSubmit
    } = useForm({});

    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const [apiReq, setApiReq] = useState({ loading: false, error: null })
    const [loading, setLoading] = useState(true)

    const get_medical = () => {
        setLoading(true)
        instance.get(`/referrer?id=${props.id}`).then(res => {
            reset(res.data)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }


    useEffect(() => {
        // On First load fetch data
        get_medical()
    }, [])

    const onSubmit = (data) => {
        data.patient_id = props.id
        setApiReq({ loading: true, error: null })
        instance.patch("/referrer", data).then(res => {
            console.log(res)
            setApiReq({ loading: false, error: null })
            showSnackbar("Data updated successfully", "success")
        }).catch(err => {
            console.log(err)
            setApiReq({ loading: false, error: null })
            showSnackbar("Failed to update data", "error")
        })
    }

    const onError = () => {

    }

    return (
        <Box>
            {loading && <LinearProgress />}
            <Typography variant="body" sx={{ color: 'rgba(84, 176, 242, 1)', fontSize: '13px' }}>The organization or person who initially referred the patient to ROMAC.</Typography>
            <SnackbarComponent />
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Box sx={{ mb: 1, mt: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item md={2}>
                            <CustomInputLabel>Type</CustomInputLabel>
                            <FormControl fullWidth>
                                <Controller
                                    name="type"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            {...field} // spread the field props into the Select component
                                        >
                                            <MenuItem value={0}>ROTARY CLUB</MenuItem>
                                            <MenuItem value={1}>PRIVATE INDIVIDUAL</MenuItem>
                                            <MenuItem value={2}>MEDICAL PERSON</MenuItem>
                                            <MenuItem value={3}>OTHER</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Title</CustomInputLabel>
                            <TextField size="small" {...register('title')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Name</CustomInputLabel>
                            <TextField size="small" {...register('name')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Mobile</CustomInputLabel>
                            <TextField size="small" {...register('mobile')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Phone Number</CustomInputLabel>
                            <TextField size="small" {...register('landline')} />
                        </Grid>
                        <Grid item md={2}>
                            <CustomInputLabel>Email</CustomInputLabel>
                            <TextField size="small" {...register('email')} />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2, display: 'flex', flexDirection: 'column' }}>
                    <CustomInputLabel>Postal Address</CustomInputLabel>
                    <TextField size="small" multiline rows={4}  {...register('address')} />

                </Box>
                <Box sx={{ my: 2, display: 'flex', flexDirection: 'column' }}>
                    <CustomInputLabel>Additional Details</CustomInputLabel>
                    <TextField size="small" multiline rows={4}  {...register('additional')} />
                </Box>
                <PatientAction>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, pt: 2, borderTop: '1px solid #ccc' }}>
                        <Button type="submit" disabled={apiReq.loading} variant="contained">SAVE</Button>
                    </Box>
                </PatientAction>
            </form>
        </Box>
    )
}