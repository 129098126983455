import React, { useEffect, useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import instance from "../../../../auth/utils/useJwt";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import MuiDataGrid from "../../../../components/tables/MUIDataGrid";

export default function AdminTrainingsList() {
    const [trainings, setTrainings] = useState([])
    const [loading, setLoading] = useState(true)
    
    const fetchTrainings = async () => {
        setLoading(true)
        instance.get("/admin/training?type=grouped").then(res => {
            setLoading(false)
            setTrainings(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])

    const COLUMNS = [
        { field: 'folder', headerName: 'Folder', width: 150 },
        { field: 'title', headerName: 'Title', width: 200, renderCell: (params) => {
            return <Link to={`/admin/trainings/${params.row.main_id}`}>{params.value}</Link>
        } },
        { field: 'description', headerName: 'Description', width: 200 },
        { field: 'duration', headerName: 'Duration', width: 200 },
        { field: 'created_at', headerName: 'Created At', width: 200 },
        { field: 'updated_at', headerName: 'Updated At', width: 200 },
    ]


    return (
        <AppLayout title="Admin - Trainings" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Trainings" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >
                    <Box>
                        <Button component={Link} to="/admin/trainings/new" variant="contained" size="small" color="primary">Add Training</Button>
                    </Box>
                </Box>

            </BContainer>
            <Box>
            <MuiDataGrid data={trainings} loading={loading} columns={COLUMNS} />
            </Box>

        </AppLayout>
    )
}