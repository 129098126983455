import AppLayout from "../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCases, setAllCasesData } from "../../redux/all_cases";

import BreadCrumbs from "../../components/BreadCrumbs";
import RegionFilter from "./components/RegionFilter";
import MuiDataGrid from "../../components/tables/MUIDataGrid";
import { COLUMNS, filterDataBySearch } from "./utilities";
import NoRegionNoRowsOverlay from "../../components/tables/comps/overlays/NoRegionNoRowOverlay";
import ExportCases from "./components/ExportDialog";
import StaredFilterSwitch from "./components/StaredSwitch";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import ViewsFilter from "./components/ViewsFilter";
import CompletedFilterSwitch from "./components/CompletedSwitch";
import { CanViewReports } from "../../redux/user_roles";

export default function AllCases() {
    const all_cases = useSelector(state => state.all_cases)
    const dispatch = useDispatch()
    const { data, loading, queryArg, filters, selectedFilters } = all_cases
    const [filtetedData, setFilteredData] = useState(data)
    const [completed, setCompleted] = useState(false)
    const canViewReports = useSelector(CanViewReports)

    const regionSelectedFilters = selectedFilters?.region?.length

    // clear searchInput on load
    useEffect(() => {
        dispatch(setAllCasesData({ key: 'searchInput', value: '' }))
    }, [])

    useEffect(() => {
        if (!completed) {
            const completedData = data.filter(item => item.status !== 'COMPLETED')
            setFilteredData(completedData)
        } else {
            setFilteredData(data)
        }
    }, [completed, data])

    useEffect(() => {
        if (!all_cases.loading)
            dispatch(fetchAllCases(queryArg))
    }, [queryArg, dispatch])
    
    return (
        <AppLayout px={0} apppadding={"0px"}>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', py: 1.7, px: 3, backgroundColor: "#fff", mb: 2, borderRadius: 'none' }}>
                {/* <Typography variant="h6">Patients</Typography> */}
                <BreadCrumbs data={[
                    { text: "Cases" }
                ]} />
                <ExportCases />
            </Box>
            <MuiDataGrid data={filtetedData} loading={loading} filters={
                <Stack direction="row" spacing={2}>
                    <RegionFilter />
                    <ViewsFilter />
                    <CompletedFilterSwitch completed={completed} setCompleted={setCompleted} />
                </Stack>
            }
                columns={COLUMNS}
                searchFunction={filterDataBySearch}
                searchPlaceholder={"Enter a Case ID or First and/or Last Name"}
                searchSX={{
                    "@media screen and (max-width: 1500px)": {
                        maxWidth: '350px'
                    },
                    "@media screen and (max-width: 1200px)": {
                        maxWidth: '300px'
                    }
                }}
                noRowsOverlay={regionSelectedFilters === 0 && NoRegionNoRowsOverlay}
                headerRightButtons={ canViewReports ?
                    <Box sx={{ py: 1, px: 2.4  }}>
                    <Link to="/reports/patient-cases" style={{ color: "#09f" }}>
                        Advanced Report
                    </Link>
                    </Box> : null
                }
            />
        </AppLayout>
    )
}