import { useState, useEffect } from "react"; 
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { API_URL } from "./configs/endpoints";
import axios from "axios";
import AuthBG from './assets/images/bg/_mg_3948 1.png'
import RouterComp from './routes/all_routes';
import { handleLogout } from "./redux/authentication";
import { deleteAccessToken, deleteRefreshToken, getAccessToken, getRefreshToken } from "./auth/utils";
import instance from "./auth/utils/useJwt";
import { setUser } from "./redux/user";
import './App.css'
import { setUserRoles } from "./redux/user_roles";
function App() {
  const user = useSelector(state => state.authentication)

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const fetchUser = async () => {
    // If current route is login, then don't fetch user
    if (window.location.pathname === "/login"){
      setIsLoading(false)
      return
    }


    try {
      instance.get(`/user`).then(res => {
        var user_data = res.data.data
        console.log(user_data)
        // pop settings key from user data
        var settings = user_data.settings
        delete user_data.settings

        // set settings in local storage
        localStorage.setItem("romac_settings", JSON.stringify(settings))
        dispatch(setUserRoles(user_data.roles))
        dispatch(setUser(user_data))

        setIsLoading(false)
      }).catch(err => {

        setIsLoading(false)
        //deleteRefreshToken()
        deleteAccessToken()
        dispatch(handleLogout())
      })

    } catch (error) {
      console.log(error)
      dispatch(handleLogout())
      setIsLoading(false)
    }
  }
 
  useEffect(() => {
    fetchUser()
  }, [])

  // Run fetch user on every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      if (getAccessToken())
        fetchUser()
      else {
        deleteAccessToken()
        dispatch(handleLogout())
      }
    }, 100000);
    return () => clearInterval(interval);
  }, []);


  if (isLoading) {
    return <div style={{ height: '100vh', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: 600 }}>
          Loading...
        </div>
    </div>
  }


  return (
    <RouterComp />
  );
}

export default App;
