import Button from "@mui/material/Button";
import AppLayout from "../../../components/layout/AppLayout";
import Box from '@mui/material/Box'

import AddIcon from '@mui/icons-material/Add';
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatients, setPatientsData } from "../../../redux/patients";

import { hasWritePermission } from "../../../redux/user";
import BreadCrumbs from "../../../components/BreadCrumbs";
import MuiDataGrid from "../../../components/tables/MUIDataGrid";
import { GRID_COLUMNS } from "./utilities";
import ViewsSelect from "./components/ViewsSelect";
import PatientAction from "../../../components/PermissionsWrappers/PatientAction";



export default function Patients() {
    const canWrite = useSelector(hasWritePermission);
   
    const patients = useSelector(state => state.patients)
    const dispatch = useDispatch()
    const { data, filters, loading, selectedFilters, queryArg } = patients

 
    // clear searchInput on load
    useEffect(() => {
        dispatch(setPatientsData({ key: 'searchInput', value: '' }))
    }, [])


    useEffect(() => {
        if (!patients.loading)
        dispatch(fetchPatients(queryArg))
    }, [queryArg])

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc', py: 1.7, px: 3, backgroundColor: "#fff", mb: 2, borderRadius: 'none' }}>
                {/* <Typography variant="h6">Patients</Typography> */}
                <BreadCrumbs data={[
                { text: "Patients" }
            ]} />
                <PatientAction>
                    <Button component={Link} to="/patients/add/accepted" variant="contained" color="primary" size="small" startIcon={<AddIcon />}>New Patient</Button>
                </PatientAction>
            </Box>
            
            <MuiDataGrid data={data} loading={loading} filters={
                <Box>
                    <ViewsSelect />
                </Box>
            }
            columns={GRID_COLUMNS}
            searchPlaceholder={"Enter a Patient ID or First and/or Last Name"}
            />
        </AppLayout>
    )
}