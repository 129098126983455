import React, { useState } from "react";
import Button from "@mui/material/Button";
import instance from "../../../auth/utils/useJwt";
import { Box, Typography } from "@mui/material";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import GeneralDialog from "../../../components/Dialogs/CustomDialog";
import { useSelector } from "react-redux";
export default function ExportCases() {
    const [exporting, setExporting] = useState(false);
    const [open, setOpen] = useState(false);
    const { queryArg, loading, selectedFilters } = useSelector(state => state.all_cases)
    const [response, setResponse] = useState(null);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    console.log(selectedFilters)
    
    

    const onSubmit = () => {
        setExporting(true);
        const regionFilters = selectedFilters?.region?.map(item => item.value).join(",");
        instance.get(`/cases/all?export=true&type=${queryArg}&region=${regionFilters}`)
            .then(res => {
                setResponse(res.data);
                window.open(res.data, '_blank', 'noreferrer');
                setExporting(false);
                handleClose();
            }).catch(err => {
                console.log(err);
                setExporting(false);
                handleClose();
            });
    };

    return (
        <>
            <Button size="small" variant="contained"
                startIcon={<ExitToAppOutlinedIcon />}
                onClick={handleClickOpen} disabled={exporting || loading}>
                {exporting ? "Exporting..." : "Export"}
            </Button>

            <GeneralDialog title={"Export Cases"} open={open} handleClose={handleClose} disabled={exporting}
                actions={<Box sx={{ display: 'flex', gap: 2 }}>
                    <Button onClick={handleClose} color="primary" disabled={exporting} variant="outlined">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={exporting} variant="contained" onClick={onSubmit}>
                        {exporting ? "Exporting..." : "Export"}
                    </Button>
                </Box>}>

                <Box>
                    <Typography variant="body2" color="text.secondary">
                        Export all cases data to a Google Sheet
                    </Typography>
                </Box>

            </GeneralDialog>
        </>
    );
}
